// always the same no matter language:
const common = {
    project_name: 'React Admin',

    locale_fr_fr: 'Français',
    locale_en_us: 'English (US)',
    locale_en_gb: 'English (GB)',
    locale_it_it: 'Italiano',
    locale_de_de: 'Deutsch',
    locale_es_es: 'Español',
    locale_fr_ch: 'Français (CH)',
    locale_fr_be: 'Français (BE)',
    locale_fr_ca: 'Français (CA)',
};

export default {
    'fr-FR': {
        translation: {
            ...common,

            button_delete_label: 'Supprimer',
            button_cancel_label: 'Annuler',
            button_confirm_label: 'Confirmer',
            button_clear_label: 'Effacer',
            button_clear_image_label: "Effacer l'image",
            button_defined_label: 'Définir',
            button_reset_label: 'Reset',

            modal_confirm_delete_title: 'Confirmation de la suppression',
            modal_confirm_delete_text: 'Souhaitez-vous vraiment supprimer cet élément ?',

            modal_confirm_generic_title: 'Confirmation',
            modal_confirm_generic_text: 'Souhaitez-vous vraiment réaliser cette action ?',

            mood_very_bad: 'Très mauvais',
            mood_bad: 'Mauvais',
            mood_normal: 'Normal',
            mood_good: 'Bon',
            mood_very_good: 'Très bon',

            role_user_label: 'Utilisateur',
            role_admin_label: 'Administrateur',
            role_guest_label: 'Invité',
            role_owner_label: 'Propriétaire',

            error_action_login_bad_credentials: 'Identifiant ou mot de passe incorrect',

            footer_contact_us: 'Nous contacter',
            constraints_required: 'Requis',
            constraints_generic: 'Veuillez vérifier cette valeur',
            constraints_email: "Format d'email invalide",
            constraints_url: "Format d'adresse URL invalide",
            constraints_phone: 'Numéro de téléphone invalide',
            constraints_not_enough_digit: 'Minimum {{min}} chiffre(s) / caractère(s)',
            constraints_too_much_digit: 'Maximum {{max}} chiffre(s) / caractère(s)',
            constraints_too_short: 'Minimum {{min}} caractères',
            constraints_too_long: 'Maximum {{max}} caractères',

            field_privacypolicy_description: 'Politique de confidentialité...',

            field_image_label: "Image",
            field_privacypolicy_label: "J'accepte la politique de confidentialité",
            field_rememberpassword_label: 'Se souvenir de moi',
            field_additionaladdress_label: "Complément d'adresse",
            field_ipaddress_label: 'Adresse IP',
            field_address_label: 'Adresse',
            field_code_label: 'Code',
            field_city_label: 'Ville',
            field_fax_label: 'Fax',
            field_facebook_label: 'Facebook',
            field_twitter_label: 'Twitter',
            field_instagram_label: 'Instagram',
            field_linkedin_label: 'LinkedIn',
            field_organizationname_label: "Nom de l'organisation",
            field_date_label: 'Date',
            field_datetime_label: 'Date et Heure',
            field_month_label: 'Mois',
            field_date_aria_label: 'Date',
            field_time_label: 'Heure',
            field_week_label: 'Semaine',
            field_number_label: 'Nombre',
            field_email_label: 'Email',
            field_url_label: 'Adresse Internet',
            field_slug_label: "Slug SEO",
            field_complement_label: "Complément",
            field_state_label: "Etat / Province",
            field_street_label: "Rue",
            field_ttl_label: "Durée de mise en cache en secondes",
            field_type_label: "Type",
            field_description_label: "Description",
            field_booktickets_label: 'Tickets par carnets',
            field_price_label: 'Prix',
            field_presentation_label: 'Présentation',
            field_externalreference_label: 'Référence partenaire',
            field_overline_label: 'Etiquette',
            field_quantity_label: "Quantité",
            field_amount_label: "Montant",
            field_firstname_label: 'Prénom',
            field_lastname_label: 'Nom',
            field_name_label: 'Nom',
            field_color_label: 'Couleur',
            field_password_label: 'Mot de passe',
            field_currentpassword_label: 'Mot de passe actuel',
            field_newpassword_label: 'Nouveau mot de passe',
            field_passwordconfirmation_label: 'Confirmation de mot de passe',
            field_newpasswordconfirmation_label: 'Confirmation du nouveau mot de passe',
            field_phone_label: 'Téléphone',
            field_otp_label: 'OTP',
            field_text_label: 'Texte',
            field_username_label: 'Email',
            field_zipcode_label: 'Code postal',
            field_gender_label: 'Sexe',
            field_birthdate_label: 'Date de naissance',

            field_image_placeholder: "Cliquez ou glissez/déposer votre image ici",
            field_generic_disabled_placeholder: 'disabled',
            field_generic_required_placeholder: 'required',
            field_ipaddress_placeholder: '192.168.0.1',
            field_additionaladdress_placeholder: 'Lieu-dit Les Alouettes',
            field_address_placeholder: '1, rue des Lavandières',
            field_code_placeholder: '...',
            field_city_placeholder: 'Paris',
            field_fax_placeholder: '0102030405',
            field_facebook_placeholder: 'https://fr-fr.facebook.com/me/',
            field_twitter_placeholder: 'https://twitter.com/me',
            field_instagram_placeholder: 'https://www.instagram.com/me',
            field_linkedin_placeholder: 'https://fr.linkedin.com/company/me',
            field_organizationname_placeholder: 'Mon organisation',
            field_number_placeholder: '1...',
            field_email_placeholder: 'marie@monemail.com',
            field_name_placeholder: 'Le nom ici',
            field_url_placeholder: 'https://',
            field_slug_placeholder: "ceci-est-un-slug",
            field_complement_placeholder: "...",
            field_state_placeholder: "...",
            field_street_placeholder: "...",
            field_description_placeholder: "...",
            field_ttl_placeholder: "0",
            field_type_placeholder: "",
            field_booktickets_placeholder: '1',
            field_price_placeholder: '100',
            field_presentation_placeholder: 'Présentation...',
            field_externalreference_placeholder: '123456',
            field_overline_placeholder: 'Etiquette',
            field_quantity_placeholder: "1",
            field_amount_placeholder: "1",
            field_firstname_placeholder: 'Marie',
            field_lastname_placeholder: 'Dupond',
            field_password_placeholder: 'Saisir un mot de passe',
            field_currentpassword_placeholder: 'Saisir votre mot de passe actuel',
            field_newpassword_placeholder: 'Saisir le nouveau mot de passe que vous souhaitez',
            field_passwordconfirmation_placeholder: 'Saisir le même mot de passe',
            field_newpasswordconfirmation_placeholder: 'Saisir de nouveau votre nouveau mot de passe',
            field_phone_placeholder: '0102030405',
            field_text_placeholder: 'Votre texte ici',
            field_username_placeholder: 'marie@monemail.com',
            field_zipcode_placeholder: '75001',
            field_birthdate_placeholder: '01/01/1970',
            search_bar_placeholder: 'Rechercher',

            field_date_format: 'dd/MM/yyyy',

            field_gender_value_man_label: 'Homme',
            field_gender_value_woman_label: 'Femme',
            field_gender_value_nonbinary_label: 'Non binaire',
            field_gender_value_notspecified_label: 'Non spécifié',

            field_code_helper: 'Saisissez le code',
            field_name_helper: "Saisissez un nom",
            field_url_helper: "Saisissez l'url cible de la redirection",
            field_slug_helper: "Utilisé dans l'url, choisissez un ensemble de mot clé pertinent pour le SEO",
            field_ttl_helper: "Saisissez un nombre de secondes > 0 pour mettre en cache (conseillé: 60)",
            field_type_helper: "Saisissez le type",
            field_helper: "Saisissez ici votre description",
            field_booktickets_helper: 'Nombre de tickets par carnet',
            field_price_helper: "Saisissez le prix",
            field_presentation_helper: "Saisissez la présentation",
            field_externalreference_helper: "Saisissez ici la référence partenaire",
            field_overline_helper: 'Etiquette',
            field_quantity_helper: "Quantité du lot",
            field_amount_helper: "Montant",
            field_complement_helper: "Saisissez le complément",
            field_state_helper: "Saisissez l'état ou la province",
            field_street_helper: "Saisissez la rue",

            login_btn_label: 'Se connecter',
            return_btn_label: 'Retour',
            video_player_btn_label: 'Découvrir',
            follow_us_button_label: 'Suivez-nous',
            gallery_title: "En direct d'Instagram",

            form_organization_title: 'Votre organisation',
            form_organization_subtitle: 'Merci de renseigner vos informations',
            form_organization_submit_label: 'Valider',

            form_change_password_title: 'Changement de mot de passe',
            form_change_password_subtitle: 'Choisissez un nouveau mot de passe',
            form_change_password_submit_label: 'Changer',

            form_login_title: 'Connexion',
            form_login_subtitle: 'Saisissez vos email et mot de passe',
            form_login_forgot_password_label: "J'ai oublié mon mot de passe...",
            form_login_submit_label: 'Connexion',
            form_login_other_login_methods_title: 'Autres méthodes de connexion',
            form_login_register_question: 'Pas encore de compte ?',
            form_login_register_label: 'Créez-en un !',

            form_user_profile_title: 'Votre profil',
            form_user_profile_subtitle: 'Modifiez vos informations',
            form_user_profile_submit_label: 'Sauvegarder',

            form_register_title: 'Inscription',
            form_register_subtitle: 'Saisissez vos email et mot de passe',
            form_register_forgot_password_label: "J'ai oublié mon mot de passe...",
            form_register_submit_label: 'Je crée mon compte',
            form_register_other_login_methods_title: 'Autres méthodes de connexion',
            form_register_login_question: 'Déjà un compte ?',
            form_register_login_label: 'Je me connecte',

            form_unlock_title: 'Déverrouillez votre mot de passe',
            form_unlock_subtitle: 'Saisissez votre mot de passe',
            form_unlock_password_label: 'Se souvenir',
            form_unlock_submit_label: 'Je crée mon compte',

            form_unlock_password_send_otp_submit_label: 'Déverrouillez',

            form_forgot_password_send_otp_title: 'Mot de passe oublié',
            form_forgot_password_send_otp_subtitle: 'Merci de saisir votre numéro de mobile',
            form_forgot_password_send_otp_submit_label: 'Envoyer le code',

            form_forgot_password_fill_in_otp_title: 'Code de vérification',
            form_forgot_password_fill_in_otp_subtitle: 'Merci de saisir le code qui vient de vous être envoyé',
            form_forgot_password_fill_in_otp_forgot_password_label: "J'ai oublié mon mot de passe...",
            form_forgot_password_fill_in_otp_submit_label: 'Valider',
            form_forgot_password_fill_in_otp_not_received: "Vous n'avez rien reçu ?",
            form_forgot_password_fill_in_otp_resend_label: 'Renvoyez moi un code',

            form_forgot_password_create_password_title: 'Nouveau mot de passe',
            form_forgot_password_create_password_subtitle: 'Merci de choisir un nouveau mot de passe',
            form_forgot_password_create_password_submit_label: 'Enregister',

            form_create_user_title: 'Créer un utilisateur',
            form_create_user_subtitle: 'Remplissez le formulaire ci-dessous pour créer un utilisateur',
            form_create_user_submit_label: 'Créer',

            form_create_organization_title: 'Créer une organisation',
            form_create_organization_subtitle: 'Remplissez le formulaire ci-dessous pour créer une organisation',
            form_create_organization_submit_label: 'Créer',

            form_create_platform_title: 'Créer une plateforme',
            form_create_platform_subtitle: 'Remplissez le formulaire ci-dessous pour créer une plateforme',
            form_create_platform_submit_label: 'Créer',

            form_create_token_title: "Créer un jeton d'accès",
            form_create_token_subtitle: "Remplissez le formulaire ci-dessous pour créer un jeton d'accès",
            form_create_token_submit_label: 'Créer',

            form_generic_submit_label: 'Envoyer',

            locales_change_value: 'Choisissez votre langue',

            previous_btn_label: 'Précédent',
            next_btn_label: 'Suivant',
            finish_btn_label: 'Terminer',

            button_login_label: 'Se connecter',

            theme_selector_btn_validate: 'Valider',
        },
        tablePagination: {
            labelRowsPerPage: 'Eléments par page :',
            nextIconButtonText: 'Page suivante',
            labelDisplayedRows: '{{from}}-{{to}} sur {{count}}',
            labelDisplayedRowsUnknown: '{{from}}-{{to}} sur plus de {{to}}',
            backIconButtonText: 'Page précédente',
        },
        table: {
            noRowsLabel: 'Aucun résultat',
            noResultsOverlayLabel: 'Aucun résultat trouvé.',
            errorOverlayDefaultLabel: 'Une erreur est survenue.',
            toolbarDensity: 'Densité',
            toolbarDensityLabel: 'Densité',
            toolbarDensityCompact: 'Compacte',
            toolbarDensityStandard: 'Standard',
            toolbarDensityComfortable: 'Lisible',
            toolbarColumns: 'Colonnes',
            toolbarColumnsLabel: 'Sélectionnez les colonnes',
            toolbarFilters: 'Filtres',
            toolbarFiltersLabel: 'Afficher les filtres',
            toolbarFiltersTooltipHide: 'Masquer les filtres',
            toolbarFiltersTooltipShow: 'Afficher les filtres',
            toolbarFiltersTooltipActive: '{{count}} filtre actif',
            toolbarFiltersTooltipActivePlural: '{{count}} filtres actifs',
            toolbarExport: 'Exporter',
            toolbarExportLabel: 'Exporter',
            toolbarExportCsv: 'Télécharger au format CSV',
            columnsPanelTextFieldLabel: 'Chercher une colonne',
            columnsPanelTextFieldPlaceholder: 'Titre de la colonne',
            columnsPanelDragIconLabel: 'Réarranger la colonne',
            columnsPanelShowAllButton: 'Afficher tout',
            columnsPanelHideAllButton: 'Masquer tout',
            filterPanelAddFilter: 'Ajouter un filtre',
            FilterPanelDeleteIconLabel: 'Supprimer',
            filterPanelOperators: 'Opérateurs',
            filterPanelOperatorAnd: 'ET',
            filterPanelOperatorOr: 'OU',
            filterPanelColumns: 'Colonnes',
            filterPanelInputLabel: 'Valeur',
            filterPanelInputPlaceholder: 'Filtrer les valeurs',
            filterOperatorContains: 'contient',
            filterOperatorEquals: 'égal',
            filterOperatorStartsWith: 'commence par',
            filterOperatorEndsWith: 'fini par',
            filterOperatorIs: 'est',
            filterOperatorNot: "n'est pas",
            filterOperatorAfter: 'est après',
            filterOperatorOnOrAfter: 'est sur ou après',
            filterOperatorBefore: 'est avant',
            filterOperatorOnOrBefore: 'est sur ou avant',
            filterOperatorIsEmpty: 'est vide',
            filterOperatorIsNotEmpty: "n'est pas vide",
            filterValueAny: "n'importe laquelle",
            filterValueTrue: 'vrai',
            filterValueFalse: 'faux',
            columnMenuLabel: 'Menu',
            columnMenuShowColumns: 'Afficher les colonnes',
            columnMenuFilter: 'Filtrer',
            columnMenuHideColumn: 'Masquer',
            columnMenuInsort: 'Annuler le tri',
            columnMenuSortAsc: 'Tri ascendant',
            columnMenuSortDesc: 'Tri descendant',
            columnHeaderFiltersTooltipActive: '{{count}} filtre actif',
            columnHeaderFiltersTooltipActivePlural: '{{count}} filtres actifs',
            columnHeaderFiltersLabel: 'Afficher les filtres',
            columnHeaderSortIconLabel: 'Trier',
            footerRowSelected: '{{count}} élément sélectionné',
            footerRowSelectedPlural: '{{count}} éléments sélectionnés',
            footerTotalRows: 'Nombre de lignes :',
            footerTotalVisibleRows: '{{visibleCount}} sur {{totalCount}}',
            checkboxSelectionHeaderName: 'Sélection à cocher',
            booleanCellTrueLabel: 'vrai',
            booleanCellFalseLabel: 'faux',
        },
    },
    'it-IT': {
        translation: {
            ...common,

            button_delete_label: 'Rimuovere',
            button_cancel_label: 'Per cancellare',
            button_confirm_label: 'Per confermare',

            modal_confirm_delete_title: 'Conferma della cancellazione',
            modal_confirm_delete_text: 'Sei sicuro di voler eliminare questo articolo?',

            modal_confirm_generic_title: 'Conferma',
            modal_confirm_generic_text: 'Sei sicuro di voler intraprendere questa azione?',

            mood_very_bad: 'Molto Brutto',
            mood_bad: 'Cattivo',
            mood_normal: 'Normale',
            mood_good: 'Bene',
            mood_very_good: 'Molto Buona',

            role_user_label: 'Utante',
            role_admin_label: 'Ammin',
            role_guest_label: 'Ospite',
            role_owner_label: 'Proprietario',

            error_action_login_bad_credentials: 'Nome utente o password errati',

            footer_contact_us: 'Contattaci',
            constraints_required: 'Necessario',
            constraints_generic: 'Si prega di controllare questo valore',
            constraints_email: 'Formato email non valido',
            constraints_url: 'Formato URL non valido',
            constraints_phone: 'Numero di telefono invalido',
            constraints_not_enough_digit: 'Minimo {{min}} cifre/caratteri',
            constraints_too_much_digit: 'Massimo {{min}} cifre/caratteri',
            constraints_too_short: 'Minimo {{min}} caratteri',
            constraints_too_long: 'Massimo {{min}} caratteri',

            field_privacypolicy_description: 'Politica sulla riservatezza...',

            field_privacypolicy_label: "Accetto l'informativa sulla privacy",
            field_rememberpassword_label: 'Ricordati di me',
            field_ipaddress_label: 'Indirizzo IP',
            field_additionaladdress_label: 'Indirizzo aggiuntivo',
            field_address_label: 'Indirizzo',
            field_code_label: 'Codificato',
            field_city_label: 'Città',
            field_fax_label: 'Fax',
            field_facebook_label: 'Facebook',
            field_gender_label: 'Sesso',
            field_twitter_label: 'Twitter',
            field_instagram_label: 'Instagram',
            field_linkedin_label: 'LinkedIn',
            field_organizationname_label: "Nome dell'organizzazione",
            field_number_label: 'Numero',
            field_datetime_label: 'Finestra di selezione della data',
            field_date_label: 'Finestra di selezione della data',
            field_month_label: 'Finestra di selezione della data',
            field_date_aria_label: 'Selezione della data',
            field_time_label: 'Scelta della settimana',
            field_week_label: 'Imposta il tuo tempo',
            field_email_label: 'E-mail',
            field_url_label: 'Indirizzo Web',
            field_slug_label: "SEO Slug",
            field_ttl_label: "Time to live in seconds",
            field_type_label: "Tipo",
            field_description_label: "Descrizione",
            field_booktickets_label: 'Tickets per book',
            field_price_label: 'Prezzo',
            field_presentation_label: 'Presentazione',
            field_externalreference_label: 'Riferimento del partner',
            field_overline_label: 'Overline',
            field_quantity_label: "Quantity",
            field_amount_label: "Amount",
            field_firstname_label: 'Conome',
            field_lastname_label: 'Nome',
            field_name_label: 'Nome',
            field_color_label: 'Colore',
            field_password_label: "Parola d'ordine",
            field_currentpassword_label: 'Parola attuale',
            field_newpassword_label: 'Nuova password',
            field_passwordconfirmation_label: 'Conferma password',
            field_newpasswordconfirmation_label: 'Conferma password',
            field_phone_label: 'Telefono',
            field_otp_label: 'OTP',
            field_text_label: 'Testo',
            field_username_label: 'Nome utente',
            field_zipcode_label: 'Codice postale',
            field_birthdate_label: 'Data di nascita',

            field_gender_value_man_label: 'Uomo',
            field_gender_value_woman_label: 'Femmina',
            field_gender_value_nonbinary_label: 'Non-binario',
            field_gender_value_notspecified_label: 'Non specificato',

            field_generic_disabled_placeholder: 'disabilitato',
            field_generic_required_placeholder: 'necessario',
            field_ipaddress_placeholder: '192.168.0.1',
            field_additionaladdress_placeholder: 'Posto chiamato Les Alouettes',
            field_address_placeholder: 'Via Apia, 12',
            field_code_placeholder: 'abcde123...',
            field_city_placeholder: 'Roma',
            field_fax_placeholder: '0102030405',
            field_facebook_placeholder: 'https://it-it.facebook.com/me/',
            field_twitter_placeholder: 'https://twitter.com/me',
            field_instagram_placeholder: 'https://www.instagram.com/me',
            field_linkedin_placeholder: 'https://it.linkedin.com/company/me',
            field_organizationname_placeholder: 'La mia organizzazione',
            field_number_placeholder: '1...',
            field_email_placeholder: 'maria@myemail.it',
            field_url_placeholder: 'https://',
            field_firstname_placeholder: 'Maria',
            field_lastname_placeholder: 'Cerrutti',
            field_password_placeholder: 'Inserisci una password',
            field_currentpassword_placeholder: 'Inserisci una password',
            field_newpassword_placeholder: 'Inserisci una password',
            field_passwordconfirmation_placeholder: 'Reinserisci la stessa password',
            field_newpasswordconfirmation_placeholder: 'Reinserisci la stessa password',
            field_phone_placeholder: '0102030405',
            field_text_placeholder: 'Il tuo testo qui',
            field_username_placeholder: 'maria@myemail.it',
            field_zipcode_placeholder: '1032',
            field_birthdate_placeholder: '01/01/1970',
            search_bar_placeholder: 'Ricerca',

            field_date_format: 'dd/MM/yyyy',

            login_btn_label: 'Per accedere',
            return_btn_label: 'Ritorno',
            video_player_btn_label: 'Scoprire',
            follow_us_button_label: 'Seguici',
            gallery_title: 'In diretta da Instagram',

            form_organization_title: 'La tua organizzazione',
            form_organization_subtitle: 'Si prega di compilare le informazioni',
            form_organization_submit_label: 'Convalidare',

            form_change_password_title: 'Cambio di password',
            form_change_password_subtitle: 'Scegli una nuova password',
            form_change_password_submit_label: 'Cambiare',

            form_login_title: 'Accesso',
            form_login_subtitle: 'Inserisci la tua email e la tua password',
            form_login_forgot_password_label: 'Ho dimenticato la mia password...',
            form_login_submit_label: 'Accesso',
            form_login_other_login_methods_title: 'Altri metodi di connessione',
            form_login_register_question: 'Non hai ancora un account?',
            form_login_register_label: 'Crearne uno!',

            form_user_profile_title: 'Il tuo profilo',
            form_user_profile_subtitle: 'Modifica le tue informazioni',
            form_user_profile_submit_label: 'Salva',

            form_register_title: 'Iscrizione',
            form_register_subtitle: 'Inserisci la tua email e la tua password',
            form_register_forgot_password_label: 'Ho dimenticato la mia password...',
            form_register_submit_label: 'Creo il mio account',
            form_register_other_login_methods_title: 'Altri metodi di connessione',
            form_register_login_question: 'Hai già un account?',
            form_register_login_label: 'io connetto',

            form_forgot_password_send_otp_title: 'Hai dimenticato la password',
            form_forgot_password_send_otp_subtitle: 'Per favore inserisci il tuo numero di cellulare',
            form_forgot_password_send_otp_submit_label: 'Manda il codice',

            form_forgot_password_fill_in_otp_title: 'Codice di verifica',
            form_forgot_password_fill_in_otp_subtitle: 'Inserisci il codice che ti è appena stato inviato',
            form_forgot_password_fill_in_otp_forgot_password_label: 'Ho dimenticato la mia password...',
            form_forgot_password_fill_in_otp_submit_label: 'Convalidare',
            form_forgot_password_fill_in_otp_not_received: 'Non hai ricevuto nulla?',
            form_forgot_password_fill_in_otp_resend_label: 'Inviami di nuovo un codice',

            form_forgot_password_create_password_title: 'Nuova password',
            form_forgot_password_create_password_subtitle: 'Scegli una nuova password',
            form_forgot_password_create_password_submit_label: 'Registrati',

            form_create_user_title: 'Crea un utente',
            form_create_user_subtitle: 'Si prega di compilare il modulo per creare un nuovo utente',
            form_create_user_submit_label: 'Creare',

            form_create_organization_title: "Crea un'organizzazione",
            form_create_organization_subtitle: 'Si prega di compilare il modulo per creare una nuova organizzazione',
            form_create_organization_submit_label: 'Creare',

            form_create_platform_title: 'Crea una piattaforma',
            form_create_platform_subtitle: 'Si prega di compilare il modulo per creare una nuova piattaforma',
            form_create_platform_submit_label: 'Creare',

            form_create_token_title: 'Crea un token',
            form_create_token_subtitle: 'Si prega di compilare il modulo per creare un nuovo token',
            form_create_token_submit_label: 'Creare',

            form_generic_submit_label: 'Mandare',

            locales_change_value: 'Scegli la tua lingua',

            previous_btn_label: 'Precedente',
            next_btn_label: 'Suivant',
            finish_btn_label: 'Finire',

            button_login_label: 'Accesso',

            theme_selector_btn_validate: 'Confermare',
        },
    },
    'en-GB': {
        translation: {
            ...common,

            button_delete_label: 'Delete',
            button_cancel_label: 'Cancel',
            button_confirm_label: 'Confirm',

            modal_confirm_delete_title: 'Delete confirmation',
            modal_confirm_delete_text: 'Do you really want to delete this item?',

            modal_confirm_generic_title: 'Confirmation',
            modal_confirm_generic_text: 'Do you really want to do this action?',

            mood_very_bad: 'Very Bad',
            mood_bad: 'Bad',
            mood_normal: 'Normal',
            mood_good: 'Good',
            mood_very_good: 'Very Good',

            role_user_label: 'User',
            role_admin_label: 'Admin',
            role_guest_label: 'Guest',
            role_owner_label: 'Owner',

            error_action_login_bad_credentials: 'Bad credentials',

            footer_contact_us: 'Contact us',
            constraints_required: 'Required',
            constraints_generic: 'Check your type-in',
            constraints_email: 'Malformed email',
            constraints_url: 'Malformed URL',
            constraints_phone: 'Malformed phone number',
            constraints_not_enough_digit: 'Min {{min}} digit(s)',
            constraints_too_much_digit: 'Max {{max}} digit(s)',
            constraints_too_short: 'Min {{min}} character(s)',
            constraints_too_long: 'Max {{max}} character(s)',

            field_privacypolicy_description: 'Privacy policy...',

            field_privacypolicy_label: 'I agree to the privacy policy',
            field_rememberpassword_label: 'Remember me',
            field_ipaddress_label: 'IP Address',
            field_additionaladdress_label: 'Additional address',
            field_address_label: 'Address',
            field_code_label: 'Code',
            field_city_label: 'City',
            field_fax_label: 'Fax',
            field_facebook_label: 'Facebook',
            field_gender_label: 'Sex',
            field_twitter_label: 'Twitter',
            field_instagram_label: 'Instagram',
            field_linkedin_label: 'LinkedIn',
            field_organizationname_label: 'Organization name',
            field_number_label: 'Number',
            field_datetime_label: 'Date Time',
            field_date_label: 'Date',
            field_month_label: 'Month',
            field_date_aria_label: 'Date',
            field_time_label: 'Week',
            field_week_label: 'Time',
            field_email_label: 'Email',
            field_url_label: 'Website',
            field_slug_label: "SEO Slug",
            field_complement_label: "Complement",
            field_state_label: "State/Province",
            field_street_label: "Street",
            field_ttl_label: "Time to live in seconds",
            field_type_label: "Type",
            field_description_label: "Description",
            field_booktickets_label: 'Tickets per book',
            field_price_label: 'Price',
            field_presentation_label: 'Presentation',
            field_externalreference_label: 'External reference',
            field_overline_label: 'Overline',
            field_quantity_label: "Quantity",
            field_amount_label: "Amount",
            field_firstname_label: 'First name',
            field_lastname_label: 'Last name',
            field_name_label: 'Last name',
            field_color_label: 'Color',
            field_password_label: 'Password',
            field_currentpassword_label: 'Current password',
            field_newpassword_label: 'New password',
            field_passwordconfirmation_label: 'Password confirmation',
            field_newpasswordconfirmation_label: 'New password confirmation',
            field_phone_label: 'Phone',
            field_otp_label: 'OTP',
            field_text_label: 'Text',
            field_username_label: 'Login',
            field_zipcode_label: 'Zip code',
            field_birthdate_label: 'Birth date',

            field_gender_value_man_label: 'Man',
            field_gender_value_woman_label: 'Woman',
            field_gender_value_nonbinary_label: 'Non-binary',
            field_gender_value_notspecified_label: 'Not specified',

            field_code_helper: 'Fill in the code',
            field_name_helper: "Fill in the name",
            field_url_helper: "Fill in the url",
            field_slug_helper: "For SEO",
            field_ttl_helper: "Fill in a number of seconds > 0 (tips: 0)",
            field_type_helper: "Fill in the type",
            field_helper: "Fill in the description",
            field_booktickets_helper: 'Tickets per books',
            field_price_helper: "Fill in the price",
            field_presentation_helper: "Fill in the presentation",
            field_externalreference_helper: "Fill in the external reference",
            field_overline_helper: 'Overline',
            field_quantity_helper: "Fill in the quantity",
            field_amount_helper: "Fill in the amount",

            field_generic_disabled_placeholder: 'disabled',
            field_generic_required_placeholder: 'required',
            field_ipaddress_placeholder: '192.168.0.1',
            field_additionaladdress_placeholder: 'Infinite Loop Road',
            field_address_placeholder: 'Infinite Loop Road, 1',
            field_code_placeholder: '...',
            field_city_placeholder: 'London',
            field_fax_placeholder: '0102030405',
            field_facebook_placeholder: 'https://en-gb.facebook.com/me/',
            field_twitter_placeholder: 'https://twitter.com/me',
            field_instagram_placeholder: 'https://www.instagram.com/me',
            field_linkedin_placeholder: 'https://en.linkedin.com/company/me',
            field_organizationname_placeholder: 'My organization',
            field_number_placeholder: '1...',
            field_email_placeholder: 'mary@myemail.com',
            field_name_placeholder: 'The name',
            field_url_placeholder: 'https://',
            field_slug_placeholder: "this-is-a-slug",
            field_complement_placeholder: "...",
            field_state_placeholder: "...",
            field_street_placeholder: "...",
            field_description_placeholder: "...",
            field_ttl_placeholder: "0",
            field_type_placeholder: "",
            field_booktickets_placeholder: '1',
            field_price_placeholder: '100',
            field_presentation_placeholder: 'Presentation...',
            field_externalreference_placeholder: '123456',
            field_overline_placeholder: 'Overline',
            field_quantity_placeholder: "1",
            field_amount_placeholder: "1",
            field_firstname_placeholder: 'Mary',
            field_lastname_placeholder: 'Smith',
            field_password_placeholder: 'Fill in the password',
            field_currentpassword_placeholder: 'Fill your current password',
            field_newpassword_placeholder: 'Fill in the new password you want',
            field_passwordconfirmation_placeholder: 'Confirm your password',
            field_newpasswordconfirmation_placeholder: 'Confirm your new password',
            field_phone_placeholder: '0102030405',
            field_text_placeholder: 'Your text here',
            field_username_placeholder: 'mary@myemail.com',
            field_zipcode_placeholder: '1020',
            field_birthdate_placeholder: '01/01/1970',
            search_bar_placeholder: 'Search',

            field_date_format: 'MM/dd/yy',

            login_btn_label: 'Login',
            return_btn_label: 'Back',
            video_player_btn_label: 'Discover',
            follow_us_button_label: 'Follow-us',
            gallery_title: 'Live from Insta',

            form_organization_title: 'Your organization',
            form_organization_subtitle: 'Please fill-in your information',
            form_organization_submit_label: 'Save',

            form_change_password_title: 'Password change',
            form_change_password_subtitle: 'Choose a new password',
            form_change_password_submit_label: 'Change',

            form_login_title: 'Login',
            form_login_subtitle: 'Please fill in your email and password',
            form_login_forgot_password_label: 'I forgot my password...',
            form_login_submit_label: 'Login',
            form_login_other_login_methods_title: 'Other sign-in methods',
            form_login_register_question: 'New ?',
            form_login_register_label: 'Register now!',

            form_register_title: 'Registration',
            form_register_subtitle: 'Please fill in your email and password',
            form_register_forgot_password_label: 'I forogot my password...',
            form_register_submit_label: 'Register now',
            form_register_other_login_methods_title: 'Other sign-in methods',
            form_register_login_question: 'Already an account?',
            form_register_login_label: 'Login',

            form_user_profile_title: 'Your profile',
            form_user_profile_subtitle: 'Edit your informations',
            form_user_profile_submit_label: 'Save',

            form_forgot_password_send_otp_title: 'Forgot password',
            form_forgot_password_send_otp_subtitle: 'Please fill in your mobile number',
            form_forgot_password_send_otp_submit_label: 'Send code',

            form_forgot_password_fill_in_otp_title: 'Verification code',
            form_forgot_password_fill_in_otp_subtitle: 'Please fill in the verification code you just received',
            form_forgot_password_fill_in_otp_forgot_password_label: 'I forgot my password...',
            form_forgot_password_fill_in_otp_submit_label: 'Validate',
            form_forgot_password_fill_in_otp_not_received: 'Not received ?',
            form_forgot_password_fill_in_otp_resend_label: 'Re-send me the code',

            form_forgot_password_create_password_title: 'New password',
            form_forgot_password_create_password_subtitle: 'Please choose a new password',
            form_forgot_password_create_password_submit_label: 'Save',

            form_create_user_title: 'Create a user',
            form_create_user_subtitle: 'Please fill-in the form to create a new user',
            form_create_user_submit_label: 'Create',

            form_create_organization_title: 'Create an organization',
            form_create_organization_subtitle: 'Please fill-in the form to create a new organization',
            form_create_organization_submit_label: 'Create',

            form_create_platform_title: 'Create a platform',
            form_create_platform_subtitle: 'Please fill-in the form to create a new platform',
            form_create_platform_submit_label: 'Create',

            form_create_token_title: 'Create a token',
            form_create_token_subtitle: 'Please fill-in the form to create a new token',
            form_create_token_submit_label: 'Create',

            form_generic_submit_label: 'Submit',

            locales_change_value: 'Select your language',

            previous_btn_label: 'Previous',
            next_btn_label: 'Next',
            finish_btn_label: 'Finish',

            button_login_label: 'Login',

            theme_selector_btn_validate: 'Confirm',
        },
        tablePagination: {
            labelRowsPerPage: 'Rows per page:',
            nextIconButtonText: 'Next page',
            labelDisplayedRows: '{{from}}-{{to}} of {{count}}',
            labelDisplayedRowsUnknown: '{{from}}-{{to}} of more than {{to}}',
            backIconButtonText: 'Previous page',
        },
        table: {
            noRowsLabel: 'No rows',
            noResultsOverlayLabel: 'No results found.',
            errorOverlayDefaultLabel: 'An error occurred.',
            toolbarDensity: 'Density',
            toolbarDensityLabel: 'Density',
            toolbarDensityCompact: 'Compact',
            toolbarDensityStandard: 'Standard',
            toolbarDensityComfortable: 'Comfortable',
            toolbarColumns: 'Columns',
            toolbarColumnsLabel: 'Select columns',
            toolbarFilters: 'Filters',
            toolbarFiltersLabel: 'Show filters',
            toolbarFiltersTooltipHide: 'Hide filters',
            toolbarFiltersTooltipShow: 'Show filters',
            toolbarFiltersTooltipActive: '{{count}} active filter',
            toolbarFiltersTooltipActivePlural: '{{count}} active filters',
            toolbarExport: 'Export',
            toolbarExportLabel: 'Export',
            toolbarExportCsv: 'Download as CSV',
            columnsPanelTextFieldLabel: 'Find column',
            columnsPanelTextFieldPlaceholder: 'Column title',
            columnsPanelDragIconLabel: 'Reorder column',
            columnsPanelShowAllButton: 'Show all',
            columnsPanelHideAllButton: 'Hide all',
            filterPanelAddFilter: 'Add filter',
            filterPanelDeleteIconLabel: 'Delete',
            filterPanelOperators: 'Operators',
            filterPanelOperatorAnd: 'And',
            filterPanelOperatorOr: 'Or',
            filterPanelColumns: 'Columns',
            filterPanelInputLabel: 'Value',
            filterPanelInputPlaceholder: 'Filter value',
            filterOperatorContains: 'contains',
            filterOperatorEquals: 'equals',
            filterOperatorStartsWith: 'starts with',
            filterOperatorEndsWith: 'ends with',
            filterOperatorIs: 'is',
            filterOperatorNot: 'is not',
            filterOperatorAfter: 'is after',
            filterOperatorOnOrAfter: 'is on or after',
            filterOperatorBefore: 'is before',
            filterOperatorOnOrBefore: 'is on or before',
            filterOperatorIsEmpty: 'is empty',
            filterOperatorIsNotEmpty: 'is not empty',
            filterValueAny: 'any',
            filterValueTrue: 'true',
            filterValueFalse: 'false',
            columnMenuLabel: 'Menu',
            columnMenuShowColumns: 'Show columns',
            columnMenuFilter: 'Filter',
            columnMenuHideColumn: 'Hide',
            columnMenuInsort: 'Unsort',
            columnMenuSortAsc: 'Sort by ASC',
            columnMenuSortDesc: 'Sort by DESC',
            columnHeaderFiltersTooltipActive: '{{count}} active filter',
            columnHeaderFiltersTooltipActivePlural: '{{count}} active filters',
            columnHeaderFiltersLabel: 'Show filters',
            columnHeaderSortIconLabel: 'Sort',
            footerRowSelected: '{{count}} row selected',
            footerRowSelectedPlural: '{{count}} rows selected',
            footerTotalRows: 'Total Rows:',
            footerTotalVisibleRows: '{{visibleCount}} of {{totalCount}}',
            checkboxSelectionHeaderName: 'Checkbox selection',
            booleanCellTrueLabel: 'true',
            booleanCellFalseLabel: 'false',
        },
    },
};
